///
/// @file components/prouducts/_product_rating.scss
///
/// \brief Styles for product rating using stars as font icon
///
///

@import '../../../../scss/theme-bootstrap';

$stars_size: 70px; // same as font size, corresponds with width
$stars_height: 17px; // add a border to the :after to test a new @setup
$stars_line_height: 17px; // add a border to the :after to test a new @setup
// for qs and spp
$stars_size_large: 100px; // same as font size, corresponds with width
$stars_height_large: 22px; // add a border to the :after to test a new @setup
$stars_line_height_large: 22px; // add a border to the :after to test a new @setup

.product-rating__stars-wrapper {
  margin: 0;
  position: relative;
  width: $stars_size;
  vertical-align: top;
  .product-full & {
    width: $stars_size_large;
  }
}

.product-rating__stars {
  color: $color-gray;
  font-size: $stars_size;
  height: $stars_height;
  line-height: $stars_line_height;
  overflow: hidden;
  text-align: left;
  width: $stars_size;
  vertical-align: top;
  position: absolute;
  top: 0;
  &::after {
    @include svg-icon-inline-mask('stars');
    content: '';
    background-color: $color-gray;
    width: $stars_size;
  }
  .product-full & {
    font-size: $stars_size_large;
    height: $stars_height_large;
    width: $stars_size_large;
  }
  &:after {
    font-size: $stars_size;
    height: $stars_height;
    line-height: $stars_line_height;
    .product-full & {
      font-size: $stars_size_large;
      height: $stars_height_large;
      width: $stars_size_large;
    }
  }
  &--on {
    color: $color-off-black;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

.product-rating__count {
  color: $color-gray;
  position: absolute;
  font-size: 12px;
  left: 110px;
  line-height: 22px;
}
